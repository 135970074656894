/** スクロール一時禁止 **/
let scroll_control = function (e) {
  e.preventDefault();
}
let fixdScrollpos;
export function no_scroll() {
  document.addEventListener("mousewheel", scroll_control, {
    passive: false
  });
  document.addEventListener("touchmove", scroll_control, {
    passive: false
  });
}

/** スクロール解除 **/
export function return_scroll() {
  document.removeEventListener("mousewheel", scroll_control, {
    passive: false
  });
  document.removeEventListener('touchmove', scroll_control, {
    passive: false
  });
}

export function modalMovieFunc() {
  var modalVideoElem = document.getElementById('modalVideo01');

  var modalVideoElem02 = document.getElementById('modalVideo02');

  $("#playAll").on('click', function () {
    no_scroll();
    $("#modalMovie01").fadeIn();
    modalVideoElem.play();
  });

  $("#modalBack").on('click', function () {
    return_scroll();
    $(this).parents(".overlay").fadeOut();
    modalVideoElem.pause();
  });

  $('#modalMovieBtn').on('click', function () {
    return_scroll();
    $(this).parents(".overlay").fadeOut();
    modalVideoElem.pause();
  });

  $("#playAll02").on('click', function () {
    no_scroll();
    $("#modalMovie02").fadeIn();
    modalVideoElem02.play();
  });

  $("#modalBack02").on('click', function () {
    return_scroll();
    $(this).parents(".overlay").fadeOut();
    modalVideoElem02.pause();
  });

  $('#modalMovieBtn02').on('click', function () {
    return_scroll();
    $(this).parents(".overlay").fadeOut();
    modalVideoElem02.pause();
  });
}